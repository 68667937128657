<template>
  <div class="main-container" v-loading="loading">
    <div class="main-header" v-if="clickArtificialData">
      <div class="header-left">
        <div id="main1" class="chart-content"></div>
        <div class="left-content">
          <div style="padding-bottom: 8px">
            更新时间：{{ orderVo.overviewUpdateTime }}
          </div>
          <div>
            网站以稳定运行<span
              style="
                color: #67c23a;
                padding: 0 8px;
                font-size: 20px;
                font-weight: 400;
              "
              >{{ orderVo.overviewRuntime }}</span
            >天
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="right-header-top">
          <div style="font-size: 24px; font-weight: bold">
            {{ orderVo.siteName }}
          </div>
          <div><el-button type="primary">生成报告</el-button></div>
        </div>
        <div class="right-header-center">
          <el-descriptions :column="1" :label-style="LS" style="width: 40%">
            <el-descriptions-item label="网站地址">
              <a
                @click="openWin(orderVo.siteUrl)"
                style="cursor: pointer; color: #3766f4"
                >{{ orderVo.siteUrl }}</a
              >
            </el-descriptions-item>
            <el-descriptions-item label="负责人">{{
              orderVo.owner
            }}</el-descriptions-item>
          </el-descriptions>
          <div class="right-header-center2">
            <div class="header-center2-one">
              <div>单项否决</div>
              <div>
                通过数量为<span> {{ orderVo.vetoPassedCount }} </span>项
              </div>
            </div>
            <div class="header-center2-border"></div>
            <div class="header-center2-two">
              <div>扣分项目</div>
              <div>
                <span>- {{ orderVo.deductTotalScore }} </span>分
              </div>
            </div>
            <div class="header-center2-border"></div>
            <div class="header-center2-two">
              <div>加分项</div>
              <div>
                <span>+ {{ orderVo.plusTotalScore }} </span>分
              </div>
            </div>
          </div>
        </div>
        <div class="right-bottom">
          <div class="rectangle-css1">
            <div class="disfelx">
              <h1 class="rectangle-css1-icon1 disfelx-padding"></h1>
              问题项
            </div>
            <div class="disfelx">
              <h1 class="number-css">{{ orderVo.failedCount }}</h1>
              <h1 class="rectangle-css1-icon11 disfelx-padding"></h1>
            </div>
          </div>
          <div
            class="rectangle-css2"
            @click="clickArtificial"
            style="cursor: pointer"
          >
            <div class="disfelx">
              <h1 class="rectangle-css1-icon2 disfelx-padding"></h1>
              待人工监测
            </div>
            <div class="disfelx">
              <h1 class="number-css">{{ orderVo.manualCount }}</h1>
              <h1 class="rectangle-css1-icon11 disfelx-padding"></h1>
            </div>
          </div>
          <div class="rectangle-css3">
            <div class="disfelx">
              <h1 class="rectangle-css1-icon3 disfelx-padding"></h1>
              监测预警
            </div>
            <div class="disfelx">
              <h1 class="number-css">{{ orderVo.warnedCount }}</h1>
              <h1 class="rectangle-css1-icon11 disfelx-padding"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-bottom">
      <!--单项否决 扣分指标 加分指标-->
      <div class="main-bottom-card" v-for="(item, i) in treeList" :key="i">
        <div class="card-message">
          <!-- 指标说明顶端 -->
          <div class="card-message-icon"></div>
          <div class="card-message-text">{{ item.standardContent }}</div>
          <div
            class="card-message-shape"
            :style="{
              backgroundColor:
                item.standardType === 2
                  ? '#5c84ff'
                  : item.standardType === 3
                  ? '#f55252'
                  : '#5bd0b9',
            }"
          >
            {{
              item.standardType === 2
                ? '通过项'
                : item.standardType === 3
                ? '总扣分 - '
                : '总得分 + '
            }}
            {{ item.standardResult }}
          </div>
        </div>
        <!-- 第一层指标整栏循环 -->
        <div
          v-for="(item1, i) in item.children"
          :key="i"
          style="margin-bottom: 12px"
        >
          <div class="numerous-card">
            <!-- 指标说明左侧大卡 -->
            <div class="numerous-card-message" v-if="item1.standardType === 2">
              <div class="message-card1"></div>
              <div style="padding: 5px 0">{{ item1.standardContent }}</div>
              <div style="color: #757d8a; padding: 5px 0">通过项</div>
              <div>
                <span style="color: #3766f4">{{ item1.standardResult }}</span
                >/{{ item1.standardScore }}
              </div>
            </div>
            <div class="numerous-card-message" v-if="item1.standardType === 3">
              <div :class="messageCard1(item1.standardContent)"></div>
              <div style="padding: 5px 0">{{ item1.standardContent }}</div>
              <div style="color: #757d8a; padding: 5px 0">扣分</div>
              <div>
                <span style="color: #f55252">{{ item1.standardResult }}</span
                >/{{ item1.standardScore }}
              </div>
            </div>
            <div class="numerous-card-message" v-if="item1.standardType === 4">
              <div :class="messageCard2(item1.standardContent)"></div>
              <div style="padding: 5px 0">{{ item1.standardContent }}</div>
              <div style="color: #757d8a; padding: 5px 0">得分</div>
              <div>
                <span style="color: #5bd0b9">{{ item1.standardResult }}</span
                >/{{ item1.standardScore }}
              </div>
            </div>
            <!-- 卡片 -->
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin-left: 3px;
              "
            >
              <!-- 卡片-循环 -->
              <div
                v-for="(item2, index) in item1.children"
                :key="index"
                class="cards-css"
                :id="`${'findiv' + item2.id}`"
                :class="getClass(item2.passStatus)"
                @click="clickCards(item2)"
              >
                <!-- 卡片上部 -->
                <div class="cards-css1">{{ item2.standardContent }}</div>
                <!-- 卡片底部 -->
                <div class="cards-css2">
                  <!-- 卡片底部左侧 -->
                  <div>
                    <div v-if="item2.standardType === 2">
                      通过项
                      <span
                        :style="{
                          color:
                            item2.passStatus === '1002'
                              ? '#f90a0a'
                              : item2.passStatus === '1001'
                              ? '#fca506'
                              : '#3766f4',
                        }"
                        >{{ item2.standardResult }}</span
                      >/{{ item2.standardScore }}
                    </div>
                    <div v-if="item2.standardType === 3">
                      扣分/总分
                      <span
                        :style="{
                          color:
                            item2.passStatus === '2002'
                              ? '#f90a0a'
                              : item2.passStatus === '2001'
                              ? '#fca506'
                              : '#3766f4',
                        }"
                        >{{ item2.standardResult }}</span
                      >/{{ item2.standardScore }}
                    </div>
                    <div v-if="item2.standardType === 4">
                      得分/总分
                      <span
                        :style="{
                          color:
                            item2.passStatus === '3000' ? '#1EBFA0' : '#1E283C',
                        }"
                        >{{ item2.standardResult }}</span
                      >/{{ item2.standardScore }}
                    </div>
                  </div>
                  <div>
                    <!-- 卡片底部右侧 -->
                    <img
                      v-show="item2.passStatus === '3002'"
                      src="@/assets/images/contentWatch/panel1.png"
                      alt
                    />
                    <img
                      v-show="
                        item2.passStatus === '1000' ||
                        item2.passStatus === '2000'
                      "
                      src="@/assets/images/contentWatch/panel2.png"
                      alt
                    />
                    <img
                      v-show="item2.passStatus === '3000'"
                      src="@/assets/images/contentWatch/panel3.png"
                      alt
                    />
                    <img
                      v-show="
                        item2.passStatus === '1001' ||
                        item2.passStatus === '2001' ||
                        item2.passStatus === '3001'
                      "
                      src="@/assets/images/contentWatch/panel4.png"
                      alt
                    />
                    <img
                      v-show="
                        item2.passStatus === '1002' ||
                        item2.passStatus === '2002'
                      "
                      src="@/assets/images/contentWatch/panel5.png"
                      alt
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'contentWatchList',
  data() {
    return {
      LS: {
        'min-width': '70px',
      },
      orderVo: {},
      treeList: [],
      loading: false,
      selfScrollTop: '',
      findDIv: null,
      clickArtificialData: true,
    };
  },
  methods: {
    initLiquidEchart() {
      // setTimeout(() => {
      let myChart = this.$echarts.init(document.getElementById('main1'));
      // 把配置和数据放这里
      var option = {
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              itemStyle: {
                color: 'rgb(102 220 31)',
              },
              width: 10,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
              showAbove: false,
            },
            title: {
              show: true,
              fontSize: 14,
              offsetCenter: [0, '50%'],
            },
            detail: {
              valueAnimation: true,
              formatter: '{value}',
              fontSize: 34,
              offsetCenter: [0, '10%'],
            },
            pointer: {
              show: false,
            },
            data: [
              {
                value: this.orderVo.totalScore,
                name: '监测分数',
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      // 使用刚指定的配置项和数据显示图表。
    },
    openWin(url) {
      if (url) {
        window.open(url);
      }
    },
    getClass(val) {
      if (val === '1000' || val === '2000') {
        return 'primary-card';
      } else if (val === '3000') {
        return 'success-card';
      } else if (val === '1001' || val === '2001' || val === '3001') {
        return 'warning-card';
      } else if (val === '1002' || val === '2002') {
        return 'danger-card';
      } else if (val === '3002') {
        return 'info-card ';
      }
    },
    messageCard1(val) {
      switch (val) {
        case '发布解读':
          return 'message-card2';
        case '办事服务':
          return 'message-card3';
        case '互动交流':
          return 'message-card4';
        case '功能设计':
          return 'message-card5';
      }
    },
    messageCard2(val) {
      switch (val) {
        case '信息发布':
          return 'message-card6';
        case '办事服务':
          return 'message-card7';
        case '互动交流':
          return 'message-card8';
        case '功能设计':
          return 'message-card9';
        case '创新发展':
          return 'message-card10';
      }
    },
    clickCards(row) {
      let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
      if (this.clickArtificialData) {
        this.$router.push({
          path: `${
            userInfo.role === 'general_user' || userInfo.role === 'test_user'
              ? '/orderCardList'
              : '/contentWatchList/orderCardList'
          }`,
          query: {
            parentId: row.id,
            scrollTop:
              document.getElementsByClassName('main-container')[0].scrollTop,
          },
        });
      } else {
        this.$router.push({
          path: '/clickArtificial/orderCardList',
          query: {
            parentId: row.id,
            scrollTop:
              document.getElementsByClassName('main-container')[0].scrollTop,
          },
        });
      }
    },
    clickArtificial() {
      this.$router.push({
        path: '/clickArtificial',
      });
    },
    getOverviewDetail() {
      console.log('2222');
      this.loading = true;
      let obj = {
        orderCode: window.sessionStorage.getItem('orderCode'),
      };
      this.$api.surveyData.getOverviewDetail(obj).then((res) => {
        if (res.code === 0) {
          this.orderVo = res.data.orderVo;
          this.treeList = res.data.treeList;
          this.initLiquidEchart();
          this.loading = false;
          this.$nextTick(() => {
            document.getElementsByClassName('main-container')[0].scrollTop =
              this.selfScrollTop;
            if (this.findDIv) {
              this.higHlightDiv(this.findDIv);
            }
          });
        }
      });
    },
    getArtificialDetail() {
      this.loading = true;
      let obj = {
        orderCode: window.sessionStorage.getItem('orderCode'),
        passStatusList: '1001,2001,3001',
        auditTypeList: 2,
      };
      this.$api.surveyData.getArtificialDetail(obj).then((res) => {
        if (res.code === 0) {
          this.treeList = res.data;
          this.loading = false;
          this.$nextTick(() => {
            document.getElementsByClassName('main-container')[0].scrollTop =
              this.selfScrollTop;
            if (this.findDIv) {
              this.higHlightDiv(this.findDIv);
            }
          });
        }
      });
    },
    // 高度卡片
    higHlightDiv(id) {
      let target = document.getElementById(id).classList;
      if (target) {
        target.add('blink-div');
        setTimeout(() => {
          target.remove('blink-div');
        }, 2000);
      }
    },
    async getInServiceOrderList() {
      await this.$api.surveyData.getInServiceOrderList().then( (res) => {
        console.log('11111');
        // console.log(res);
        if(res.code===0 && res.data.length !==0){
            window.sessionStorage.setItem('orderCode',res.data[0].orderCode);
        }
      });
    },
  },
  computed: {},
  mounted() {
    this.resizefun = () => {
      this.$echarts.init(document.getElementById('main1')).resize(); //多个echarts则在此处添加
    };
    window.addEventListener('resize', this.resizefun);
  },
  async created() {
    // 运营订单进入
    if (this.$route.query.orderCode) {
      window.sessionStorage.setItem('orderCode', this.$route.query.orderCode);
    }
    await this.getInServiceOrderList();
    if (this.clickArtificialData) {
      await this.getOverviewDetail();
    } else {
      await this.getArtificialDetail();
    }
  },
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      if (
        form.path === '/contentWatchList' ||
        form.path === '/clickArtificial/orderCardList'
      ) {
        vm.clickArtificialData = false;
      }
      // 卡片点击定位
      if (
        form.path === '/contentWatchList/orderCardList' ||
        form.path === '/clickArtificial/orderCardList'
      ) {
        vm.selfScrollTop = form.query.scrollTop;
        vm.findDIv = `${'findiv' + form.query.parentId}`;
      }
      // 主页情况
      // 1.列表详情进入显示全部内容。点击主页得待人工监测进入显示人工得（待人工-不显示基本信息）
      // 2.全部内容指标返回。待人工监测指标返回（待人工-不显示基本信息）---以下是隐藏逻辑
      // 全部和带人工数据接口也不同，跳转得指标详情虽然相同页面但是路由的返回信息不同，router中定义
    });
  },
  //移除事件监听
  beforeDestroy() {
    window.removeEventListener('resize', this.resizefun);
    this.resizefun = null;
  },
};
</script>
<style lang="scss" scoped>
// 页面html分为两部分-上部分样式
.main-header {
  background-color: #fff;
  height: 16.5rem;
  width: 100%;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 1rem;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  .header-left {
    width: 25%;
    min-width: 200px;
    height: 100%;
    .chart-content {
      width: 100%;
      min-width: 200px;
      height: 70%;
    }
    .left-content {
      width: 100%;
      height: 30%;
      padding-top: 12px;
      text-align: center;
    }
  }
  .header-right {
    width: 75%;
    height: 100%;
    padding-top: 10px;
    .right-header-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .right-header-center {
      width: 100%;
      height: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right-header-center2 {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-center2-one {
          width: 40%;
          height: 100%;
          padding-top: 24px;
          text-align: right;
          padding-right: 25px;
          div:first-child {
            color: #8a96af;
            padding-bottom: 0.7rem;
          }
          span {
            font-size: 30px;
          }
        }
        .header-center2-two {
          width: 30%;
          height: 100%;
          padding-top: 24px;
          text-align: right;
          padding-right: 25px;
          div:first-child {
            color: #8a96af;
            padding-bottom: 0.7rem;
          }
          span {
            font-size: 30px;
          }
        }
        .header-center2-border {
          border-left: 1px solid #e9e9e9;
          width: 1px;
          height: 40px;
        }
      }
    }
    .right-bottom {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: space-between;
      .disfelx {
        display: flex;
        color: #fff;
        font-size: 16px;
      }
      .disfelx-padding {
        padding: 0 20px;
      }
      .number-css {
        color: #fff;
        font-size: 26px;
      }
      .rectangle-css1-icon11 {
        width: 5px;
        height: 30px;
        background: url('~@/assets/images/contentWatch/watch1.png') center
          center no-repeat;
      }
      .rectangle-css1 {
        width: 17rem;
        height: 3.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url('~@/assets/images/contentWatch/content1.png') center
          center no-repeat;
        background-size: 100% 100%;
        .rectangle-css1-icon1 {
          width: 17px;
          height: 22px;
          background: url('~@/assets/images/contentWatch/watch2.png') center
            center no-repeat;
        }
      }
      .rectangle-css2 {
        width: 17rem;
        height: 3.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url('~@/assets/images/contentWatch/content2.png') center
          center no-repeat;
        background-size: 100% 100%;
        .rectangle-css1-icon2 {
          width: 17px;
          height: 22px;
          background: url('~@/assets/images/contentWatch/watch3.png') center
            center no-repeat;
        }
      }
      .rectangle-css3 {
        width: 17rem;
        height: 3.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url('~@/assets/images/contentWatch/content3.png') center
          center no-repeat;
        background-size: 100% 100%;
        .rectangle-css1-icon3 {
          width: 17px;
          height: 22px;
          background: url('~@/assets/images/contentWatch/watch4.png') center
            center no-repeat;
        }
      }
    }
  }
}
// 页面html分为两部分-下部分样式
.main-bottom {
  width: 100%;
  height: 100%;
  .main-bottom-card {
    width: 100%;
    height: auto;
    padding: 0 12px 6px 24px;
    margin-top: 10px;
    background: #fff;
  }
  // 指标说明顶部样式
  .card-message {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: left;
    align-items: center;
    .card-message-icon {
      width: 13.5px;
      height: 13.5px;
      background: url('~@/assets/images/contentWatch/message-icon.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .card-message-text {
      font-size: 18px;
      padding: 0 10px;
    }
    .card-message-shape {
      width: 86px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      margin-left: 10px;
      font-size: 14px;
      border-radius: 4px 4px 4px 4px;
    }
  }
  // 多卡片样式
  .numerous-card {
    width: 100%;
    display: flex;
  }
  .numerous-card-message {
    width: 120px;
    background: #d2d2e6;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .message-card1 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message1.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card2 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message2.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card3 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message3.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card4 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message4.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card5 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message5.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card6 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message6.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card7 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message7.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card8 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message8.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card9 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message9.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
    .message-card10 {
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/contentWatch/message10.png') center
        center no-repeat;
      background-size: 100% 100%;
    }
  }
  // 卡片样式
  .cards-css {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }
  .cards-css1 {
    width: 112px;
    height: 36px;
    margin-bottom: 24px;
    text-align: center;
    align-items: center;
  }
  .cards-css2 {
    width: 130px;
    height: 35px;
    line-height: 35px;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
  }
  .primary-card {
    width: 150px;
    height: 130px;
    margin: 5px;
    background: url('~@/assets/images/contentWatch/primary-card.png') center
      center no-repeat;
    background-size: 100% 100%;
  }
  .success-card {
    width: 150px;
    height: 130px;
    margin: 5px;
    background: url('~@/assets/images/contentWatch/success-card.png') center
      center no-repeat;
    background-size: 100% 100%;
  }
  .warning-card {
    width: 150px;
    height: 130px;
    margin: 5px;
    background: url('~@/assets/images/contentWatch/warning-card.png') center
      center no-repeat;
    background-size: 100% 100%;
  }
  .danger-card {
    width: 150px;
    height: 130px;
    margin: 5px;
    background: url('~@/assets/images/contentWatch/danger-card.png') center
      center no-repeat;
    background-size: 100% 100%;
  }
  .info-card {
    width: 150px;
    height: 130px;
    margin: 5px;
    background: url('~@/assets/images/contentWatch/info-card.png') center center
      no-repeat;
    background-size: 100% 100%;
  }
}
.blink-div {
  border: 2px solid #f44444;
  border-radius: 4px 4px 4px 4px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    border-color: #f44444;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: #f44444;
  }
}
</style>